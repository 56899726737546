.viewcart {
  .giftwrap-content {
    .giftwrap-panel {
      &__form {
        @include breakpoint($medium-up) {
          width: 80%;
        }
      }
      &__checkbox-container {
        float: $ldirection;
        margin-bottom: 10px;
        width: 65%;
        @include breakpoint($medium-up) {
          float: none;
          width: 100%;
        }
        .label-content {
          line-height: 1.5;
        }
      }
    }
  }
}
