.interstitial-popup {
  text-align: center;
  .popup-plaque-content &__title {
    font-family: $font--heading;
    line-height: 1;
    font-size: 25px;
    padding: 5px 0 5px 0;
    @include breakpoint($medium-up) {
      font-size: 29px;
    }
  }
  .popup-plaque-content &__txt {
    margin-bottom: 17px;
    font-size: 12px;
    p {
      font-size: 12px;
      margin: 0;
    }
  }
  .popup-plaque-content {
    padding: 0;
    margin: 0;
    @include breakpoint($medium-up) {
      padding-top: 10px;
    }
    ul.language-list {
      padding: 0;
      li {
        display: block;
        margin: 0;
      }
    }
  }
  &__header {
    margin: 0;
  }
  &__content {
    &.content-line {
      border-top: 1px solid $color-lighter-gray;
      margin-top: 17px;
      margin-bottom: 10px;
      padding-top: 15px;
      @include breakpoint($medium-up) {
        margin-bottom: 0;
      }
    }
  }
  &-cbox--ca {
    #cboxLoadedContent {
      padding: 30px 10px 30px 10px;
    }
  }
}

.signup-tout-wrapper {
  .signup-tout-background-image {
    min-height: 640px;
    @include breakpoint($medium-up) {
      min-height: 400px;
    }
  }
  .signup-tout-content {
    &__panel {
      &--right {
        width: 100%;
        @include breakpoint($medium-up) {
          width: unset;
        }
      }
    }
  }
}

.site-footer {
  &-pc__column--1 {
    @include breakpoint($landscape-up) {
      min-height: 225px;
    }
  }
}

/********** Language Specific Styles **********/
[lang='fr'] {
  .customer-service {
    &__contact_info {
      .cs-contact-info__livechat {
        display: none;
      }
    }
  }
}

.optanon-status-editable,
.optanon-status-on {
  input[type='checkbox'] {
    & ~ label {
      &:before {
        display: none;
      }
    }
  }
}

#cookie_popover_container {
  display: none !important;
}

.order-details-page__content {
  .order-info__item {
    &.total {
      text-transform: unset;
    }
  }
}

.spp__container {
  .product-full {
    &.product-full-v1 {
      .review-snippet {
        .pr-snippet-stars-reco-inline {
          .pr-snippet-write-review-link {
            // @to override the existing styles
            font-size: 12px !important;/* stylelint-disable-line */
          }
        }
      }
      .sticky-add-to-bag {
        .product-add-to-favorites div {
          text-transform: unset;
        }
      }
    }
  }
}
